import Content from "./content.svelte"
import Description from "./description.svelte"
import Footer from "./footer.svelte"
import Header from "./header.svelte"
import Media from "./media.svelte"
import Root from "./root.svelte"
import Title from "./title.svelte"

export const Card = {
  Root,
  Content,
  Description,
  Footer,
  Header,
  Title,
  Media,
}

export type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
