<script lang="ts">
  import { cn } from "#lib/utils"
  import type { HTMLAttributes, HTMLImgAttributes } from "svelte/elements"
  import { Card } from "."

  type $$Props = Pick<HTMLImgAttributes, "src" | "alt"> &
    Pick<HTMLAttributes<HTMLImageElement>, "class"> & {
      containerClass?: string
      alt?: string
    }

  let className: $$Props["class"] = undefined
  export { className as class }

  export let containerClass: $$Props["containerClass"] = ""

  export let src: $$Props["src"] = ""
  export let alt = ""
</script>

<Card.Content class={cn("pl-0 pr-0", containerClass)}>
  <img {src} {alt} title={alt} class={className} />
</Card.Content>
