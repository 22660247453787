<script lang="ts">
  import { cn } from "#lib/utils"
  import type { HTMLAttributes } from "svelte/elements"

  type $$Props = HTMLAttributes<HTMLParagraphElement>

  let className: $$Props["class"] = undefined
  export { className as class }
</script>

<p
  class={cn("tracking-1 text-general-secondary-text font-small-500", className)}
  {...$$restProps}
>
  <slot />
</p>
