<script lang="ts">
  import { cn } from "#lib/utils"
  import type { HTMLAttributes } from "svelte/elements"
  import type { HeadingLevel } from "."

  type $$Props = HTMLAttributes<HTMLHeadingElement> & {
    tag?: HeadingLevel
  }

  let className: $$Props["class"] = undefined
  export let tag: $$Props["tag"] = "h3"
  export { className as class }
</script>

<svelte:element
  this={tag}
  class={cn(
    "font-semibold leading-none tracking-1 text-general-primary-text",
    className
  )}
  {...$$restProps}
>
  <slot />
</svelte:element>
